<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <h5 slot="header" class="mb-0">
              <b-button size="sm" :to="{ name: 'system.menu.list' }" class="mr-2"><i class="fa fa-chevron-left"></i>
                Буцах</b-button>
              <span v-if="this.$route.params.id > 0">Засах: <strong class="text-primary">{{ form.name }}</strong></span>
              <span v-else>Шинээр нэмэх</span>
            </h5>
            <b-form-group id="input-group-parent" label="Parent:" label-for="input-parent">
              <multiselect v-model="form.parent" :options="parents" placeholder="Parent" label="name" track-by="id"
                :multiple="false"></multiselect>
            </b-form-group>

            <b-form-group id="input-group-name" label="Нэр:" label-for="input-name">
              <b-form-input id="input-name" v-model="form.name" type="text" required placeholder="Нэр"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-code" label="Код:" label-for="input-code">
              <b-form-input id="input-code" v-model="form.code" required placeholder="Код"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-icon" label="Icon:" label-for="input-icon">
              <b-form-input id="input-icon" v-model="form.icon" placeholder="icon"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-action" label="Action:" label-for="input-action">
              <b-form-input id="input-action" v-model="form.action" placeholder="action"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-displayIndex" label="Эрэмбэ:" label-for="input-displayIndex">
              <b-form-input id="input-displayIndex" v-model="form.displayIndex" type="number"
                placeholder="Эрэмбэ"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-roles" label="Хандах эрх:" label-for="input-roles">
              <multiselect v-model="form.roles" :options="roles" placeholder="Хандах эрх" label="name" track-by="id"
                :multiple="true"></multiselect>
            </b-form-group>

            <b-form-group id="input-group-4" class="mb-0">
              <b-form-checkbox value="true" v-model="form.isActive" switch>Идэвхтэй</b-form-checkbox>
            </b-form-group>


            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3">Шинэчлэх</b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Menu.edit',
  data: function () {
    return {
      isLoading: false,
      form: {
        name: '',
        code: '',
        icon: '',
        action: '',
        isActive: false,
        displayIndex: '',
        roles: [],
        parent: null
      },
      show: true,
      roles: [],
      parents: [],
      save_url: 'admin/create_menu'
    }
  },
  created: function () {
    this.checkSession();
    this.getRoles()
    this.getParents()
    if (this.$route.params.id > 0) {
      this.$data.save_url = 'admin/update_menu'
      this.loadData()
    }
  },
  methods: {
      async getParents() {
        try {
          const response = await axios.get('https://servicek.numur.mn/admin/get_menus', {
            params: {
              page: 1,
              per_page: 100,
              orderBy: 'name',
              orderDirection: 'ASC',
              search: ''
            },
            headers: {
          ...this.$store.getters.httpHeader,  // Include existing headers
          'Content-Type': 'application/json'  // Add Content-Type header
        },
          });

          // Assuming response.data contains the data (since you're using axios)
          var _datas = response.data.data;
          var _menus = [];

          for (let i in _datas) {
            if (_datas[i].parent == null) {
              _menus.push(_datas[i]);
            }
          }

          this.$data.parents = _menus;
        } catch (error) {
          console.error('Error fetching parent menus:', error);
        }
      
    },
    getRoles: function () {
      this.$http.get(this.$config.API_URL + 'UserWebService/get_roles',
        {
          params: {
            page: 1,
            per_page: 100,
            orderBy: 'name',
            orderDirection: 'ASC',
            search: ''
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then(response => {
          this.$data.roles = response.body.data
        });
    },
    loadData: function () {
      this.isLoading = true;
      // Prepare the request data
      const requestData = {
        id: this.$route.params.id
      };
      // Make the axios POST request to localhost
      axios.post('https://servicek.numur.mn/admin/get_menu', requestData, {
        headers: {
          ...this.$store.getters.httpHeader,  // Spread existing headers from the store
          'Content-Type': 'application/json'  // Make sure Content-Type is set to JSON
        }
      })
        .then(response => {
          this.isLoading = false;
          this.$data.form = response.data;  // Assuming response.data contains the form data
        })
        .catch(error => {
          this.isLoading = false;
          console.error("Error fetching data:", error);
          this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger');
        });
    },
    onSubmit(evt) {
      this.isLoading = true
 
        axios.post(
    this.$config.NUMUR_MS_ADMIN_URL + this.$data.save_url,
    this.$data.form,  // Send the form data directly as JSON
    {
      headers: {
        ...this.$store.getters.httpHeader,
        'Content-Type': 'application/json'  // Ensure Content-Type is 'application/json'
      }
    }
        ).then(
          response => {
            this.isLoading = false;
            this.showToast('Амжилттай', 'Амжилттай хадгалагдлаа', 'success')
            this.$router.reload()
            if (this.$route.params.id == 0) {
              this.onReset(evt)
            }
          },
          response => {
            this.isLoading = false;
            this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
          }
        );
    },
    onReset(evt) {
      evt.preventDefault()
      this.$data.form = {
        name: '',
        code: '',
        icon: '',
        action: '',
        isActive: false,
        displayIndex: '',
        roles: '',
        parent: null
      }
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>